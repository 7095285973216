/* eslint-disable */
// @ts-nocheck
/**
 * 公共：销售阶段枚举
 * https://yapi.weierai.com/project/86/interface/api/1385
 **/

// import api1385 from '@/apis/86/api1385'
// @ts-ignore
import { service } from '@/apis/http'

export class IReqapi1385 {
  'service-name': string | number
}
export class IResapi1385 {
  code?: number
  msg?: string
  data?: {
    configs?: {
      value?: {}
      label?: string
    }[]
    userId?: string
  }
}
export type IReq1385 = Omit<IReqapi1385, 'shopId'>
export default (
  data?: IReq1385,
  config?: boolean | { showMsg?: Boolean; needCatch?: Boolean }
): Promise<IResapi1385> =>
  service({
    method: 'GET',
    url: '/api/common/saleStage/Enum',
    yapi: '86',
    headers: {
      'service-name': 'spider'
    },
    params: data,
    ...(typeof config === 'boolean' ? { showMsg: config } : config)
  })
