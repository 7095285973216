import getSubdomain from '@/utils/getdomain'
const subDomain = getSubdomain()

export function getBaseUrl(env) {
  return (
    {
      mock: '//yapi.weierai.com/mock/',
      dev: '//ai-gateway-dev',
      qa: '//ai-gateway-qa',
      pre: '//gateway-pre',
      production: '//gateway'
    }[env] +
    '.' +
    subDomain
  )
}

export const getLoginUrl = (env) => {
  return `//djk-login${env !== 'production' ? `-${env}` : ''}.${subDomain}`
}
