import { type RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    name: '403',
    path: '/403',
    meta: {
      title: '403',
      isMenu: false
    },
    component: () => import('@/views/error/403')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
      isMenu: false
    },
    component: () => import('@/views/error/404')
  },
  {
    path: '/500',
    name: '500',
    meta: {
      title: '500',
      isMenu: false
    },
    component: () => import('@/views/error/500')
  }
]
export default routes
