import { type RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/customerManage',
    name: '客户管理',
    component: () => import('@/views/customerManage'),
    meta: {
      title: '客户管理',
      icon: 'icon-zhuboguanli'
    }
  }
]
export default routes
