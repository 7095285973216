import { JSONStringify } from '@/utils'
import SlsWebLogger from 'js-sls-logger'
import { isDevelop } from '@/utils'
interface msgObj {
  msg?: string
}

interface defaultParams {
  pid: string
}

export default class Log {
  content: object
  logger: any
  constructor(params: defaultParams) {
    this.logger = new SlsWebLogger({
      host: 'cn-hangzhou.log.aliyuncs.com',
      project: 'wr-fe',
      logstore: 'web-tracking',
      time: 3
    })
    this.content = Object.assign({}, params)
  }

  // 设置默认值
  setConfig(opts: object) {
    Object.assign(this.content, opts)
  }

  // 发送日志
  send(...msgs) {
    if (msgs.length === 0) return
    // 处理不同格式的日志
    let msgObj: msgObj = {}
    if (msgs.length > 1) {
      msgObj.msg = Object.values(msgs)
        .map((obj) => JSONStringify(obj))
        .join(' ')
      // 打印查看
      console.log(msgObj.msg)
    } else if (msgs.length === 1) {
      const msg = msgs[0]
      if (typeof msg === 'string') {
        msgObj.msg = msg
        // 打印查看
        console.log(msgObj.msg)
      } else if (typeof msg === 'object') {
        msgObj = msg
        // 打印查看
        console.log(msgObj)
      }
    }
    // 开发环境不发送日志
    if (!isDevelop) {
      this.logger.send({
        ...this.content,
        ...msgObj
      })
    }
  }

  // 异常日志
  sendError(msg: string | object) {
    this.send({
      desc: '接口异常',
      logType: 'ERROR',
      msg
    })
  }
}
