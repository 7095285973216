/* eslint-disable */
// @ts-nocheck
/**
 * 获取当前登录人名称
 * https://yapi.weierai.com/project/93/interface/api/15239
 **/

// import api15239 from '@/apis/93/api15239'
// @ts-ignore
import { service } from '@/apis/http'

export class IReqapi15239 {}
export class IResapi15239 {
  code: number
  msg: string
  data: string
}
export type IReq15239 = Omit<IReqapi15239, 'shopId'>
export default (
  data?: IReq15239,
  config?: boolean | { showMsg?: Boolean; needCatch?: Boolean }
): Promise<IResapi15239> =>
  service({
    method: 'GET',
    url: '/currentLoginName',
    yapi: '93',
    headers: {
      'service-name': 'watchdog'
    },
    params: data,
    ...(typeof config === 'boolean' ? { showMsg: config } : config)
  })
