import { getBindingLogParams, trackEventLog } from './utils'
import router from '@/router'
import { $log } from '@/plugins'

export const clickEvent = (binding) => {
  const { value } = binding
  const { extend, extra } = getBindingLogParams(binding, value)
  trackEventLog(
    $log,
    'click',
    {
      path: router.currentRoute.value.path,
      meta: router.currentRoute.value.meta
    },
    value.key,
    value.explain,
    extend,
    extra
  )
}
