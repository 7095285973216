import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'normalize.css'
import 'ant-design-vue/dist/antd.css'
import './style/main.less'
import './style/master.less'
import { createFromIconfontCN } from '@ant-design/icons-vue'

import { setupRouter } from '@/router'
import store from '@/store/install'
import _a from '@/assets/font_485724_4m765ove2vb/iconfont.js?url'
// 应用实时监控服务ARMS
import { browserLogger } from '@/plugins/alife-logger'
import { installTrackLog } from './directives/log'
import { isDevelop } from '@/utils'

const app = createApp(App)

// 注册全局访问属性
// installSdk(app)
// 去除cancel请求的控制台reject报错
window.addEventListener('unhandledrejection', (event) => {
  if (!!(event.reason && event.reason.__CANCEL__)) {
    event && event.preventDefault()
  }
})
const myIcon = createFromIconfontCN({
  scriptUrl: _a
})
app.component('MyIcon', myIcon)

// 初始化用户行为指令log上传
installTrackLog(app)
// 挂载路由
setupRouter(app)
app.use(store).use(Antd).mount('#app')
