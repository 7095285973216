/* eslint-disable */
// @ts-nocheck
/**
 * 获得当前登录人登录产品的菜单树
 * https://yapi.weierai.com/project/93/interface/api/37919
 **/

// import api37919 from '@/apis/93/api37919'
// @ts-ignore
import { service } from '@/apis/http'

export class IReqapi37919 {
  url?: string
  shopOrgId?: string
}
export class IResapi37919 {
  code: number
  msg?: string
  data?: {
    nodeId: string
    parentId: string
    menuPermissionId: string
    functionPermissionId: string
    name: string
    code: string
    /**
     * 路径
     */
    content: string
    children: {
      nodeId: string
      parentId: string
      menuPermissionId: string
      functionPermissionId: string
      name: string
      code: string
      children: string[]
    }[]
  }[]
}
export type IReq37919 = Omit<IReqapi37919, 'shopId'>
export default (
  data?: IReq37919,
  config?: boolean | { showMsg?: Boolean; needCatch?: Boolean }
): Promise<IResapi37919> =>
  service({
    method: 'POST',
    url: '/api/currentLogin/selectMenuTreeByPermissionQuery',
    yapi: '93',
    headers: {
      'service-name': 'sheepdog'
    },
    data: data,
    ...(typeof config === 'boolean' ? { showMsg: config } : config)
  })
