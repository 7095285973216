/**
 * getLogParams 从当前组件获取额外的动态参数_logExtend和_logExtra
 */
export const getLogParams = (context = {} as any) => {
  // 预留组件自己定义传入的参数
  const _logExtend = context._logExtend || {}
  const _logExtra = context._logExtra || {}

  return {
    extend: {
      ..._logExtend
    },
    extra: {
      ..._logExtra
    }
  }
}

export const getBindingLogParams = (binding, context) => {
  // 额外支持从binding传入extend和extra参数
  const { value = {} } = binding

  const { extend, extra } = getLogParams(context)
  return {
    extend: {
      ...extend,
      ...(value.extend || {})
    },
    extra: {
      ...extra,
      ...(value.extra || {})
    }
  }
}

export const trackEventLog = ($log, eventType, name, key, explain, extend, extra) => {
  console.log('[trackEventLog----]', name, key, explain, extend, extra)
  $log.send({
    clientType: 'Desktop',
    eventType,
    pageType: typeof name === 'object' ? JSON.stringify(name) : name,
    explain,
    funcExtend: typeof extend === 'object' ? JSON.stringify(extend) : extend,
    funcExtra: typeof extra === 'object' ? JSON.stringify(extra) : extra
  })
}
