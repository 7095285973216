import {
  createMenuInterceptFn,
  getPermissionsIntercept,
  createUserInterceptFn
} from '@/components/u-vue-router-intercept/src'

// import api38808 from '@/apis/93/api38808'
import api37919 from '@/apis/93/api37919'

import { routeStore } from '@/components/u-vue-router-intercept/src'
import { useUserStore } from '@/store'

const getPremissions = async () => {
  const res = await api37919({})
  return {
    data: res.data
  }
}

export const createRouterGuards = (params) => {
  return [
    // createUserInterceptFn(api38808, '@@OrgId')(params),
    createMenuInterceptFn(getPremissions)(params),
    getPermissionsIntercept(params),
    // 路由拦截补充
    ((params) => {
      return ({ to, from, next }) => {
        const userStore = useUserStore()
        userStore.setMenus(routeStore.menus)
        // if (!userStore.userName) {
        userStore.getUserName()
        // }
        if (!userStore.userId) {
          // userStore.getUserId()
        }
        next()
      }
    })(params)
  ]
}
