import { defineStore } from 'pinia'

interface LocalState {
  locale: string
}

export const useLocaleStore = defineStore({
  id: 'locale',
  state: (): LocalState => ({ locale: 'zh_CN' }),
  getters: {
    getLocale(): string {
      return this.locale ?? 'zh_CN'
    }
  },
  actions: {
    setLocale(locale) {
      this.locale = locale
    }
  }
})
