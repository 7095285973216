/**
 * 获取字符串
 */
export function JSONStringify(msg) {
  try {
    if (typeof msg === 'string') {
      return msg
    } else {
      return JSON.stringify(msg)
    }
  } catch (e) {
    return 'JSON.stringify 失败: ' + e
  }
}
