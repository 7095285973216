import { createPinia } from 'pinia'
import persistedstate from 'pinia-persistedstate'
const store = createPinia()

store.use(
  persistedstate({
    key: 'client',
    storage: window.sessionStorage
  })
)
export default store
