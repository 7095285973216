import { reactive } from 'vue'
let pathId = 0
/**
 * @type {{selectOrg: {}, permissions: [], menus: []}}
 * @property selectOrg 被选中的组织
 * @property menus 菜单数据
 * @property permissions 权限数据
 */
export const routeStore = reactive({ menus: [], selectOrg: {}, permissions: [] })

interface defaultInter {
  [key: string]: any
}

export const getFlatRoutes = (function getFlatRoutes() {
  const nodes = []
  return function (list?: never[]) {
    if (nodes.length) {
      return nodes
    }
    const stack = []
    if (list) {
      stack.push(...list)
      while (stack.length) {
        const { children = [], ...item } = stack.pop() as unknown as defaultInter
        nodes.push(item as never)
        const _children = children || []
        for (let i = _children.length - 1; i >= 0; i--) {
          if (!_children[i].parentName) {
            _children[i].parentName = item.name
          }
          stack.push(_children[i] as never)
        }
      }
    }
    return nodes
  }
})()
/**
 * map结构的路由
 * @type {function(...[*]=)}
 */
export const getRouteMap = (function getRouteMap() {
  let obj = <any>null
  return function () {
    if (obj) {
      return obj
    }
    const routes = getFlatRoutes()
    obj = routes.reduce((res, item: defaultInter) => {
      res[item.path] = item
      return res
    }, {})
    return obj
  }
})()
/**
 * 查询面包屑list
 * @param name
 * @returns {[]}
 */
const inBreadCrumbList = (function getBreadCrumbList() {
  const json = {}
  return function (name) {
    if (json[name]) {
      return json[name]
    }
    const res = []
    const routes = getFlatRoutes()
    let route = <any>null
    do {
      route = routes.find((route: defaultInter) => route.name === name)
      route && res.unshift(route as never)
    } while (route && (name = route.parentName))
    json[name] = res
    return res
  }
})()
export function getBreadCrumbList(name) {
  const list = inBreadCrumbList(name)
  return list
}

/**
 * 高亮的route.path
 * @param name
 * @returns {*}
 */
export function getActiveMenu(name) {
  const routes = getFlatRoutes()
  let route = <any>null
  do {
    route = routes.find((route: defaultInter) => route.name === name)
  } while (route && route.meta && route.meta.isMenu === false && (name = route.parentName))
  return route && route.path
}

/**
 * 获取菜单list
 * @type {function(...[*]=)}
 */
export function createMenuListFn(routes, useMock, { iconClassName = 'icon-menu' } = {}) {
  /**
   * 没有icon的去路由表查询icon
   * @param icon
   * @param path
   * @param name
   * @returns {*|string}
   */
  function getIcon(icon, { path, name }) {
    if (icon) {
      return icon
    } else {
      const route = routes.find(({ meta, path: routePath }) => {
        return routePath === path || (meta && name === meta.title)
      })
      const className = route && route.meta && route.meta.icon
      return className || ''
    }
  }

  /**
   * 增强 menuList
   * 末级菜单 <=> 有效路由 <=> 点击触发$router.push
   * 非末级菜单 <=> 有子菜单的菜单
   * @param menuList
   * @param showIcon
   * @returns {*}
   */
  function iteratorMenuList(menuList, showIcon = true) {
    return menuList.reduce((res, item) => {
      console.log(item)
      const children = item.children || []
      const path = '/' + (item.content || '403').trim().replace(/^\//, '')
      const isRoute = children.length ? 0 : 1
      const menu = {
        isRoute,
        menuName: item.name,
        name: item.name,
        code: item.code,
        path: isRoute ? path : '/ROUTE_PATH__' + pathId++, // 非末级菜单 path特殊处理
        prefixIcon: showIcon ? `${getIcon(item.icon, { path, name: item.name })}` : ''
      } as defaultInter
      let subMenu = []
      if (!menu.isRoute && children && children.length) {
        subMenu = iteratorMenuList(children, false) // 深度优先
        menu.children = subMenu
        menu.isRoute = subMenu.length ? 0 : 1
      }
      const routeMap = getRouteMap()
      const route = routeMap[path]
      if (route && route.meta) {
        routeMap[path].meta.title = item.name // 保证面包屑名称同菜单名
      }
      // 确认存在有效子菜单 防止方舟写了不存在的路径
      if (route || subMenu.length) {
        // 有路由 或者 有子菜单的才能被加入
        res.push(menu)
        if (
          // 末级菜单 没有配置路径 移除 // 排除403菜单
          (menu.isRoute && (path === '/403' || (route && !route.component))) ||
          // 配置了路径 但是要求不在菜单显示 移除 // 删除不需要显示的菜单
          (path !== '/403' && route && route.meta && route.meta.isMenu === false)
        ) {
          // 末级菜单 没有配置路径 移除
          res.pop() // 排除403菜单
        }
      }
      return res
    }, [])
  }

  /**
   * 路由表获取菜单树
   */
  function getMenuListForRoutes(list, showIcon = true) {
    return list.reduce((res, item) => {
      const { meta, children } = item
      if (meta && meta.title && meta.isMenu !== false) {
        const c = getMenuListForRoutes(children || [], false)
        res.push({
          isRoute: c.length ? 0 : 1,
          menuName: meta.title,
          name: item.name,
          code: item.code,
          path: item.path,
          prefixIcon: showIcon ? `${meta.icon || ''}` : '',
          children: c
        })
      }
      return res
    }, [])
  }

  return function (permissions) {
    const menus = useMock
      ? getMenuListForRoutes(routes)
      : iteratorMenuList(permissions.filter(({ type }) => type !== 1))
    useMock && console.warn('mock模式使用路由表做菜单树，菜单全量加载不走拦截')
    routeStore.menus = menus
    routeStore.permissions = permissions
  }
}

/**
 * 1. 最后一个fn next 执行 router.next
 * 2. 非最后一个fn: next 执行 nextFn
 * 3. 任意fn next 如果传参了，流程中断，执行 router.next
 * 4. 任意fn 都共享上下文 to, from, next ? router.next : nextFn
 */
export function interceptor(router, defaultIntercepts = []) {
  router.beforeEach((to, from, next) => {
    // TODO: to.fullPath === currentRoute.fullPath 会死循环
    // console.log('currentRoute', router.currentRoute.fullPath, to.fullPath)
    const intercepts = to.meta && to.meta.intercepts ? to.meta.intercepts : defaultIntercepts
    if (!intercepts || intercepts.length === 0) {
      next()
    } else {
      const context = {
        to,
        from,
        router,
        next
      }
      let i = -1

      const nextFn = (...args) => {
        ++i
        const fn = intercepts[i]
        if (!fn) {
          // console.log(`进入第${i}个方法, 执行next`);
          return next(...args)
        }
        if (args.length > 0) {
          // console.log(`进入第${i}个方法, 执行next`);
          return next(...args)
        } else {
          // console.log(`进入第${i}个方法, 执行nextFn`);
          return intercepts[i]({
            ...context,
            next: nextFn
          })
        }
      }
      nextFn()
    }
  })
  router.afterEach((to, from, failure) => {
    // console.log('[router.afterEach]', to, from, failure)
  })

  router.onError((error) => {
    // console.log('[router.onError]', error, '路由错误')
  })

  return router
}

/**
 * 通过菜单确定一级路由的redirect
 * @param menuList
 * @returns {*}
 */
export function getIndexRedirectForMenuList(menuList) {
  const menu = menuList[0]
  if (menu.path && menu.path !== '/') {
    if (menu.children && menu.children.length) {
      return menu.children[0].path
    } else {
      return menu.path
    }
  }
}

const getMenuFlatList = (function () {
  const nodes = []
  return function (list: never[]) {
    if (nodes.length) {
      return nodes
    }
    const stack = []
    if (list) {
      stack.push(...list)
      while (stack.length) {
        const { children = [], ...item } = stack.pop() as unknown as defaultInter
        nodes.push(item.path as never)
        const _children = children || []
        for (let i = _children.length - 1; i >= 0; i--) {
          stack.push(_children[i] as never)
        }
      }
    }
    return nodes
  }
})()

export const getPermissionsIntercept =
  ({ useMock }) =>
  ({ to, from, next, router }) => {
    if (to.path === '/') {
      // 如果是首页就去redirect
      getIndexRedirectForMenuList(routeStore.menus)
      const redirect = getIndexRedirectForMenuList(routeStore.menus)
      if (redirect && redirect !== '/') {
        next(redirect)
      }
    } else {
      if (useMock) {
        next() // mock模式不用控制路由权限
      } else {
        const menuFlatList = getMenuFlatList(routeStore.permissions)
        if (menuFlatList.includes(to.path as never) || to.path === '/403') {
          next()
        } else if (to.meta) {
          const breadcrumbs = inBreadCrumbList(to.name)
          const index = breadcrumbs.findIndex(({ meta }) => meta && meta.isMenu !== false)
          if (index > -1) {
            // 当前route树
            const breadCrumb = breadcrumbs[index - 1] // 取最后一个isMenu: true 判断他是否有权限
            if (breadCrumb && !menuFlatList.includes(breadCrumb.path as never)) {
              next('/403')
            }
          } else {
            // index ===  -1的情况下 直接
            next('/403')
          }
          next()
        } else {
          console.error(`请添加路由${to.name}的meta`)
          next('/403')
        }
      }
    }
  }

export const createMenuInterceptFn =
  (api) =>
  ({ routes, useMock, ...options }) => {
    let flag = false
    return ({ next }) => {
      if (flag) {
        next()
      } else {
        api()
          .then((res) => {
            const list = (res && res.data) || []
            try {
              createMenuListFn(routes, useMock, options)(list)
            } catch (e) {
              console.error(e)
            }
            flag = true
            if (!useMock && !list.length) {
              next() // fixme 申请去添加页面权限
            } else {
              next()
            }
          })
          .catch((error) => {
            console.log(error)

            flag = true
            next('/403') // fixme 去兜底页
          })
      }
    }
  }

export const createUserInterceptFn =
  (api, storageKey) =>
  ({ useMock }) => {
    let flag = false
    return ({ next }) => {
      if (flag) {
        next()
      } else {
        if (useMock) {
          routeStore.selectOrg = { value: 'mock', label: 'mock' }
          next()
        } else {
          const orgId = localStorage.getItem(storageKey) || ''
          Promise.all(
            [
              api({ orgName: '', pageIndex: 1, pageSize: 1 }),
              orgId && api({ orgId, pageIndex: 1, pageSize: 1 })
            ].filter(Boolean)
          )
            .then(([firstData, storageRes]) => {
              flag = true
              let data
              if (storageRes && storageRes.data && storageRes.data.count > 0) {
                data = storageRes.data.data[0]
              } else if (firstData && firstData.data && firstData.data.count > 0) {
                data = firstData.data.data[0]
              }
              if (data) {
                window.OPERATE_ORG = data.value
                data.value && localStorage.setItem(storageKey, data.value)
                routeStore.selectOrg = data
                next()
              } else {
                next('/403') // fixme 无组织权限 申请去添加组织权限页面
              }
            })
            .catch(() => {
              flag = true
              next('/403') // fixme 接口报错 去兜底页
            })
        }
      }
    }
  }
