import { defineStore } from 'pinia'

// 长时间不操作默认锁屏时间
const initTime = 60 * 60

export type LockscreenState = {
  isLock: boolean // 是否锁屏
  lockTime: number
}

export const useLockscreenStore = defineStore({
  id: 'lockscreen',
  state: (): LockscreenState => ({
    isLock: true, // 是否锁屏
    lockTime: 0
  }),
  actions: {
    setLock(isLock) {
      this.isLock = isLock
    },
    setLockTime(lockTime = initTime) {
      this.lockTime = lockTime
    }
  }
})
