import { clickEvent } from './eventTypes'

export const log = {
  mounted(el, binding) {
    const { arg } = binding
    if (arg === 'click') {
      el.removeEventListener('click', () => clickEvent(binding))
      el.addEventListener('click', () => clickEvent(binding))
    }
    // todo 其他交互事件
  }
}

export const installTrackLog = (app) => {
  app.directive('log', log)
}
