import { message as Message } from 'ant-design-vue'
import { loginUrl } from '@/utils'

export const toLoginPage = (showMessage = true, href) => {
  if (showMessage) Message.error('登录已过期，请重新登录')
  if (process.env.NODE_ENV === 'development') {
    location.href = `${loginUrl}?domain=djk-admin&redirect=${encodeURIComponent(href)}`
  } else {
    location.href = `${loginUrl}?redirect=${encodeURIComponent(
      location.pathname === '/login' ? location.origin : href
    )}`
  }
}
