import { defineStore } from 'pinia'
import { routes } from '@/router'
import { routeStore } from '@/components/u-vue-router-intercept/src'

// 当前用户组织名
import api2615 from '@/apis/93/api2615'
// 当前登录昵称
import api15239 from '@/apis/93/api15239'
// 获取销售阶段下的 userId
import api1385 from '@/apis/86/api1385'

interface UserState {
  menus: any[]
  orgId: string
  userId: string
  userName: string
  orgName: string
}

export const useUserStore = defineStore({
  id: 'useUserStore',
  state: (): UserState => {
    return {
      menus: [],
      orgId: localStorage.getItem('@@OrgId') || '',
      userId: '',
      userName: '',
      orgName: ''
    }
  },
  getters: {
    getMenus(): string[] {
      return this.menus
    }
  },
  actions: {
    SetUserName(query) {
      this.userName = query
    },
    SetOrgName(query) {
      this.orgName = query
    },
    SetUserId(query) {
      this.userId = query
    },
    SetOrgId(query) {
      this.orgId = query
    },
    setMenus(menus) {
      this.menus = menus
    },
    // 获取当前登录组织信息
    async getOrgName() {
      const res = await api2615()
      if (res.data) {
        this.orgName = res.data as string
      }
    },
    // 获取当前登录人昵称
    async getUserName() {
      const res = await api15239()
      if (res.data) {
        this.userName = res.data as string
      }
    },
    // 获取当前 userId
    async getUserId() {
      const res = await api1385()
      if (res.data?.userId) {
        this.userId = res.data?.userId
      }
    }
  }
})
