/* eslint-disable */
// @ts-nocheck
/**
 * 获取当前登录人组织名
 * https://yapi.weierai.com/project/93/interface/api/2615
 **/

// import api2615 from '@/apis/93/api2615'
// @ts-ignore
import { service } from '@/apis/http'

export class IReqapi2615 {}
export class IResapi2615 {
  code: number
  msg?: string
  /**
   * 组织名
   */
  data?: string
}
export type IReq2615 = Omit<IReqapi2615, 'shopId'>
export default (
  data?: IReq2615,
  config?: boolean | { showMsg?: Boolean; needCatch?: Boolean }
): Promise<IResapi2615> =>
  service({
    method: 'GET',
    url: '/api/currentLogin/orgName',
    yapi: '93',
    headers: {
      'service-name': 'sheepdog'
    },
    params: data,
    ...(typeof config === 'boolean' ? { showMsg: config } : config)
  })
