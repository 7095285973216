import { getBaseUrl, getLoginUrl } from './baseUrl'

/**
 * 环境
 * true: 本地开发
 * false: 开发、测试、预发、线上 生产环境
 */
export const isDevelop = import.meta.env.VITE_NODE_ENV === 'development'

/**
 * 开发、测试、预发、线上环境
 */
export const env = import.meta.env.VITE_HTTP_ENV || 'production'

export const url = getBaseUrl(env)
export const loginUrl = getLoginUrl(env)
