import * as VueRouter from 'vue-router'
import { type App } from 'vue'
import { $log } from '@/plugins'

import { createRouterGuards } from './router-guards'
import staticModules from './staticModules/basic'
import errors from './staticModules/errors'
import { whiteNameList } from './constant'

import { useRouter } from '@/components/u-vue-router-intercept/src'

export const routes: Array<VueRouter.RouteRecordRaw> = [
  {
    name: 'Layout',
    path: '/',
    redirect: '/customerManage',
    component: () => import('@/views/layout'),
    meta: {
      title: '首页'
    },
    children: [...staticModules, ...errors]
  }
]

export const { router } = useRouter(VueRouter)({
  mode: 'history',
  routes,
  useMock: window.WEIER && window.WEIER.isMock,
  defaultIntercept: (params) => {
    return createRouterGuards(params)
  }
})

// 重置 router
export function resetRouter(router) {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !whiteNameList.some((n) => n === name)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export async function setupRouter(app: App) {
  app.use(router)
  // 路由准备就绪后挂载APP实例
  await router.isReady()
}

export default router
