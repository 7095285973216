import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import moment from 'moment' //在原有的基础上加上下面三行代码
import 'moment/dist/locale/zh-cn'
moment.locale('zh-cn')
import zhCn from 'ant-design-vue/es/locale/zh_CN'
import Qimo from './components/Qimo'

export default defineComponent({
  name: 'App',
  setup() {
    return () => (
      <a-config-provider locale={zhCn}>
        <RouterView />
        {/* <Qimo /> */}
      </a-config-provider>
    )
  }
})
