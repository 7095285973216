import { getFlatRoutes, interceptor, routeStore } from './utils'
export {
  getFlatRoutes,
  getActiveMenu,
  getBreadCrumbList,
  getPermissionsIntercept,
  createMenuInterceptFn,
  createMenuListFn,
  createUserInterceptFn,
  interceptor as default,
  routeStore
} from './utils'

export const getWrapperRouter = ({ createRouter, createWebHistory, createWebHashHistory }) =>
  class {
    enhanced = false
    mainRoutes
    options: any
    router: any
    constructor(options) {
      /**
       * @property {Boolean} useMenu
       * VueRouter options
       */
      this.options = options
      const { routes, mainRoutes } = this.enhanceRoutes(options.routes)
      this.router = routes
      this.mainRoutes = mainRoutes
      this.create()
    }

    /**
     * hash模式 补全前缀
     * @param routes
     * @param base
     */
    enhanceHashRouter(routes, base) {
      if (base === '/' || !base) return
      ;(routes || []).forEach((item) => {
        if (item.path === '/') {
          item.path = base
        } else if (item.path.startsWith('/') && !item.path.startsWith(base)) {
          item.path = base + item.path
        }
        if (item.redirect && item.redirect.startsWith('/') && !item.redirect.startsWith(base)) {
          item.redirect = base + item.redirect
        }
        this.enhanceHashRouter(item.children, base)
      })
    }

    findIndexRoute(routes) {
      const index = routes.findIndex(({ path }) => path === '/')
      if (index === -1) {
        throw new Error('请添加一级路由 path="/"')
      }
      const [{ children = [], ...indexRoute }] = routes.splice(index, 1)
      return [indexRoute, children, routes]
    }

    /**
     * 路由增强
     * 添加*为404
     * 扁平化 '/'的子路由
     * @returns {*}
     */
    enhanceRoutes(routeConfig) {
      const { mode = 'history', base, closeGlobalRedirect = false } = this.options
      const [indexRoute, mainRoutes, routes] = this.findIndexRoute(routeConfig)
      indexRoute.children = getFlatRoutes(mainRoutes).filter(({ component }) => !!component) // 确认路由有component
      indexRoute.props = routeStore
      if (mode !== 'history' && window.__POWERED_BY_QIANKUN__ && !this.enhanced) {
        // todo 待验证
        // 乾坤微前端 hash模式 路由添加前缀 history模式直接设置base就可以添加
        this.enhanceHashRouter(indexRoute, base) // 补全前缀
        this.enhanced = true
      }
      !closeGlobalRedirect &&
        routes.push(indexRoute, { path: '/:catchAll(.*)', name: '*', redirect: '/404' }) // 找不到的默认404页面
      return {
        routes,
        mainRoutes
      }
    }

    create() {
      const {
        mode = 'history',
        base,
        scrollBehavior,
        defaultIntercept,
        routes,
        ...options
      } = this.options

      // 实例化VueRouter
      this.router = createRouter({
        base: mode === 'history' && window.__POWERED_BY_QIANKUN__ ? base : '/',
        history: mode === 'history' ? createWebHistory() : createWebHashHistory(),
        scrollBehavior: scrollBehavior || (() => ({ y: 0 })),
        routes: routes
      })

      // 注册中间件拦截器
      const interceptList =
        typeof defaultIntercept === 'function'
          ? defaultIntercept({ ...options, routes: this.mainRoutes })
          : defaultIntercept || []
      if (!Array.isArray(interceptList)) {
        throw new Error('defaultIntercept is Array or a function return Array')
      }
      interceptor(this.router, interceptList.filter(Boolean as never))
    }

    destroy() {
      this.router = null
    }
  }

// 路由包装器
export function useRouter(VueRouter) {
  return function (options) {
    const WrapperRouter = getWrapperRouter(VueRouter)
    const router = new WrapperRouter(options)
    return router
  }
}
