import axios from 'axios'
import { $log } from '@/plugins'
import { message as Message } from 'ant-design-vue'
import { url, env } from '@/utils'
import { toLoginPage } from '@/utils/tools'
import { useUserStore } from '@/store'
import { JSONParse, JSONStringify } from '@/utils'

// 拦截重复请求
const pending = new Map()

// 超时请求告警
const timeOut = {
  startTime: <number>0,
  count: <number>0
}

// 网络断连请求告警
const networkError = {
  startTime: <number>0,
  count: <number>0
}

// 其他类型请求告警
const otherError = {
  startTime: <number>0,
  count: <number>0
}

let networkErrors = <any>[]
let errorTimer = <any>null

// 处理轮询时错误提示限流
const rejectHandle = (rejectError, delay, msg) => {
  rejectError.startTime = !rejectError.startTime ? new Date().getTime() : rejectError.startTime
  if (rejectError.count === 0) {
    Message.error(msg)
  }
  if (new Date().getTime() - rejectError.startTime > delay) {
    rejectError.count = 0
    rejectError.startTime = new Date().getTime()
  }
  rejectError.count++
  if (rejectError.count >= 30) {
    rejectError.count = 0
    rejectError.startTime = new Date().getTime()
  }
  return Promise.reject(msg)
}

/**
 * 添加请求
 * @param {Object} config
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join('&')
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        pending.set(url, cancel)
      }
    })
}

/**
 * 移除请求
 * @param {Object} config
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join('&')
  if (pending.has(url)) {
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
}

/**
 * 清空pending中的请求 [跳转路由]
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}

// 白名单
// const whiteApi: Array<string> = []
// Log 日志白名单
const logWhiteApi: Array<string> = [
  'knowledgeSpeechcraft',
  'getShopAuthorization',
  'getShopAuthorizationByPlatForm'
]

// 创建axios实例
const service = axios.create({
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    // 'pro-url': env === 'production' ? '//djk-admin.whalegoo.com' : `//djk-admin-${env}.whalegoo.com`
    'pro-url': `//${location.hostname}`
  },
  timeout: 5 * 1000 // 请求超时时间
})

// 上传日志和问答不需要打印到日志里
const filterInteface = (url, arr = logWhiteApi) => arr.some((a) => url.includes(a))

// request拦截器
service.interceptors.request.use(
  (config: any) => {
    console.log(config)
    // mock
    if (env === 'mock') {
      config.url = config.yapi + config.url
    } else {
      config.url = url + config.url
    }
    // if (config.method.toLocaleUpperCase() === 'GET') {
    //   if (typeof config.params !== 'object') {
    //     config.params = {}
    //   }
    //   config.params.orgId = useUserStore().orgId
    // } else if (config.method.toLocaleUpperCase() === 'POST') {
    //   if (!config.data) {
    //     config.data = '{}'
    //   }
    //   const data = JSONParse(config.data)
    //   data.orgId = useUserStore().orgId
    //   config.data = JSONStringify(data)
    // }
    removePending(config)
    addPending(config)
    if (typeof XMLHttpRequest !== 'undefined') {
      if (localStorage.getItem('__login')) {
        config.headers.account = localStorage.getItem('__login')
      }
    }
    const configUrl = config.url.replace(/\?(.)*/, '')
    // if (!whiteApi.includes(configUrl)) {
    // }
    return config
  },
  (error) => {
    const content = {
      requestError: error
    }
    $log.sendError(content)
    Promise.reject(error)
  }
)

// 防止高频重复日志
let prevErrorLog = {
  url: '',
  msg: ''
}

// response 拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config)
    if (
      !filterInteface(response.config.url) &&
      response.status === 200 &&
      response.data &&
      response.data.code === 0
    ) {
      $log.send({
        url: response.config.url,
        data: response.config.data,
        desc: '接口报错',
        msg: response.data
      })
      Message.error(`${response.data.msg}`)
    }
    if (response.data.code === -1) {
      return toLoginPage(true, location.href)
    }
    return Promise.resolve(response.data)
  },
  (error) => {
    try {
      const { response = {} } = error
      if (response.status === 403) {
        toLoginPage(true, location.href)
        return
      }
      if (error && error.config && filterInteface(error.config.url)) {
        if (error.message.indexOf('Network Error') !== -1 && !error.config._retry) {
          rejectHandle(networkError, 5 * 60 * 1000, '网络波动不稳定')
          return
        }
        if (
          error.code === 'ECONNABORTED' &&
          error.message.indexOf('timeout') !== -1 &&
          !error.config._retry
        ) {
          rejectHandle(timeOut, 5 * 60 * 1000, '请求超时')
          return
        }
        if (error.message && !error.config._retry) {
          rejectHandle(otherError, 5 * 60 * 1000, error.message)
          return
        }
      }
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }
      if (
        error &&
        error.config &&
        (prevErrorLog.url !== error.config.url || prevErrorLog.msg !== error.message)
      ) {
        const content = {
          responseError: error,
          url: error.config.url,
          msg: error.message
        }
        $log.sendError(content)
        prevErrorLog = content
      }
      // 处理同时请求过多，错误时导致页面提示频繁
      networkErrors.push(error)
      if (!errorTimer) {
        errorTimer = setTimeout(() => {
          Promise.race(networkErrors.map((_error) => Promise.resolve(_error))).then((_) => {
            Message.error(`${_}`)
            errorTimer = null
            networkErrors = []
          })
        })
      }
    } catch (catchError) {
      const content = {
        responseError: error,
        catchError: catchError
      }
      $log.sendError(content)
      return Promise.reject(catchError)
    }
    return Promise.reject(error)
  }
)

export { service }
